import { defineStyle } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

export const FormHelperTextStyles = defineStyle({
  color: customColors.text.secondary,
  m: 0,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'start',
  fontSize: 'sm',
  lineHeight: 'sm',
  gap: 2.5,
  pl: 2,
});

export const FormHelperTextIconStyles = defineStyle({
  lineHeight: 'xs',
  height: 4,
  width: 4,
  flexShrink: 0,
  mt: 0.5,
});

export const FormHelperTextContentStyles = defineStyle({
  width: 'full',
});
