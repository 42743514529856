import { gql, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { History } from 'history';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Route } from 'react-router-dom';

import { useIsLoggedIn } from 'src/app/graph/hooks/useIsLoggedIn';
import { trackUserId } from 'src/lib/analytics';
import { useFullstoryInitializer } from 'src/lib/analytics/useFullstoryInitializer';
import Config from 'src/lib/config';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

import { useLocalStorage } from './useLocalStorage';
import { useQueryParam } from './useQueryParams';

export const SentryRoute = Sentry.withSentryRouting(Route);
export const initSentry = (history: History<unknown>) => {
  Sentry.init({
    dsn: 'https://4f2fd0298c0044f5907713d5766d144a@sentry.io/283526',
    environment: Config.settings.env,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        beforeNavigate: (context) => {
          return { ...context, name: window?.location?.pathname };
        },
      }),
    ],
    tracesSampleRate: 0.01,
    attachStacktrace: true,
    enabled: Config.sentryEnabled,
    ...(Config.gitCommitHash && { release: Config.gitCommitHash }),
  });
};

// We added a local storage key for whether or not someone has accepted our
// GDPR notice. This is only necessary when logged out, given our TOS.
export const useTelemetryInitializer = (isEmbedded: boolean) => {
  const runTelemetry = useQueryParam('runTelemetry');
  const { consentToFullStory } = useFullstoryInitializer();

  // trackingConsent was set by the old cookie toast prior to OneTrust implementation
  // the goal is to eventually find a way to migrate these cookied users to
  // OneTrust so we can pull this out
  const [trackingConsent] = useLocalStorage('trackingConsent');

  const { data, loading } = useQuery(gql<
    GraphQLTypes.RoutedAppMeQuery,
    GraphQLTypes.RoutedAppMeQueryVariables
  >`
    query RoutedAppMeQuery {
      me {
        id
      }
    }
  `);

  const { isLoggedIn, loading: loadingIsLoggedIn } = useIsLoggedIn();

  React.useEffect(() => {
    // on the embeddable Explorer, we default to opting out of telemetry
    // because dev tools can't run telemetry for legal reasons, and we
    // need all previous versions of dev tools to opt out
    // 02/08/2023 - don't run telemetry until loading is false
    if (
      !loading &&
      !loadingIsLoggedIn &&
      (!isEmbedded || runTelemetry === 'true')
    ) {
      const loggedInOrConsented = isLoggedIn || trackingConsent === 'consented';
      initializeTelemetryDependencies(loggedInOrConsented, consentToFullStory);
      if (loggedInOrConsented) Sentry.setUser({ id: data?.me?.id });
    }
  }, [
    consentToFullStory,
    data?.me?.id,
    isEmbedded,
    isLoggedIn,
    loading,
    loadingIsLoggedIn,
    runTelemetry,
    trackingConsent,
  ]);

  useEffect(() => {
    if (!loading && !loadingIsLoggedIn) {
      // The Google Analytics User ID policy requires that you stop any measurement
      // based on the user ID once the user signs out of your website.
      // https://support.google.com/tagmanager/answer/4565987
      trackUserId(isLoggedIn ? data?.me?.id : undefined);
    }
  }, [isLoggedIn, loadingIsLoggedIn, data?.me?.id, loading]);
};

let hasInitialized = false;
const initializeTelemetryDependencies = (
  loggedInOrConsented: boolean,
  consentToFullStory: () => void,
) => {
  if (hasInitialized) return;
  hasInitialized = true;

  if (
    // only inject OneTrust for non logged in users
    loggedInOrConsented ||
    // don't inject OneTrust for local dev
    window.location.hostname === 'localhost' ||
    // load ad-script free gtm container for sandboxes
    window.location.pathname.includes('sandbox')
  ) {
    // init tracking scripts for logged in users
    // this gtm container contains no ad tracking pixels
    initTrackingScripts('GTM-M964NS9', true);
    consentToFullStory();
  } else {
    const el = document.createElement('script');
    // only init tracking scripts once OneTrust has loaded
    el.onload = () => {
      // using common gtm container for logged out users containing
      // ad tracking / conversion event pixels
      // this allows us to optimize our paid ad conversions
      // GTM now handles blocking of scripts based on consent within this
      // GTM container (Mon, Dec 5, 2022)
      initTrackingScripts('GTM-KS9CMZW');
      consentToFullStory();
    };
    el.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    el.setAttribute('charset', 'UTF-8');
    el.setAttribute(
      'data-domain-script',
      'bd9564d9-5534-4188-8249-1f3cd852a89f',
    );
    document.head.appendChild(el);
  }
};

// see https://developers.google.com/analytics/devguides/collection/gtagjs/sending-data#handle_timeouts
function createFunctionWithTimeout(callback: Function, optTimeout?: number) {
  let called = false;
  function fn() {
    if (!called) {
      called = true;
      callback();
    }
  }
  setTimeout(fn, optTimeout || 1000);
  return fn;
}

function initTrackingScripts(
  gtmContainerId: string,
  loggedInOrConsented?: boolean,
) {
  /* load Google Tag Manager */
  TagManager.initialize({
    gtmId: gtmContainerId,
  });

  /** load google analytics */
  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag() {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer?.push(arguments);
  };
  gtag('js', new Date());
  // disable default page_view event from firing so we can send it ourselves
  // to make use of the event callback and wait until ga has had a chance to load
  // before removing utms from the url
  gtag('config', 'G-0BGG5V2W2K', {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    send_page_view: false,
  });
  gtag('config', 'G-13FXVW9L6R', {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    send_page_view: false,
  });
  gtag('event', 'page_view', {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    event_callback: () => {
      // remove utm params after analytics has fired the page_view event
      // wrapped in a timeout to ensure utms are still pulled in the event
      // ga doesn't load or this callback fails
      createFunctionWithTimeout(() => {
        // only do this if we have query params present
        if (!window.location.search) return;
        window.history.replaceState(
          {},
          document.title,
          `?${stringify(
            _.omit(parse(window.location.search), [
              Config.queryParameters.Referrer,
              Config.queryParameters.Campaign,
              Config.queryParameters.Medium,
              Config.queryParameters.Source,
            ]),
          )}${window.location.hash}`,
        );
      });
    },
  });

  /** load segment */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const analytics: any = (window.analytics = window.analytics || []);
  if (!analytics.initialize) {
    if (analytics.invoked) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      window.console &&
        // eslint-disable-next-line no-console
        console.error &&
        // eslint-disable-next-line no-console
        console.error('Segment snippet included twice.');
    } else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
      ];
      analytics.factory = function af(t: unknown) {
        return function afa() {
          // eslint-disable-next-line prefer-rest-params
          const e = Array.prototype.slice.call(arguments);
          e.unshift(t);
          analytics.push(e);
          return analytics;
        };
      };
      for (let t = 0; t < analytics.methods.length; t++) {
        const e = analytics.methods[t];
        analytics[e] = analytics.factory(e);
      }
      analytics.load = function load(t: string) {
        const e = document.createElement('script');
        e.type = 'text/javascript';
        // append OneTrust blocking class for logged out users because we need
        // to wait for consent
        if (!loggedInOrConsented) {
          e.classList.add('optanon-category-C0002');
        }
        e.async = !0;
        e.src = `${
          document.location.protocol === 'https:' ? 'https://' : 'http://'
        }cdn.segment.com/analytics.js/v1/${t}/analytics.min.js`;
        const n = document.getElementsByTagName('script')[0];
        n?.parentNode?.insertBefore(e, n);
      };
      analytics.SNIPPET_VERSION = '4.0.0';
      if (Config.settings.segmentKey) {
        analytics.load(Config.settings.segmentKey);
      }
    }
  }
}
