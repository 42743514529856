import { useMemo } from 'react';

import { useCurrentPlan } from './currentPlanV2Migration';
import { useCurrentAccountId } from './useCurrentAccountId';

export const useCanAccessProposals = () => {
  const [accountId] = useCurrentAccountId();

  const currentPlan = useCurrentPlan({
    skip: !accountId,
    accountId: accountId as string,
  });

  return useMemo(
    () =>
      !!currentPlan.currentPlan?.allCapabilities.find(
        (capability) => capability.label === 'proposals',
      )?.value,
    [currentPlan.currentPlan?.allCapabilities],
  );
};
