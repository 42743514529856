import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { RouteConfig } from '../lib/routeConfig/RouteConfig';

/**
 * Hook to extract meaningful, safely typed information from the URL with a
 * known RouteConfig
 *
 * Returns an object with all match parameters, search parameters, and
 * state.
 *
 * This is intended to be forgiving and will attempt to strip out data that
 * doesn't match the schema, like when a tab value is invalid.
 */

type OneOrMore<T> = [T, ...T[]];

type RouteConfigForParams<
  MatchParams extends Record<string, unknown>,
  SearchParams extends Record<string, unknown>,
  State,
  Hash,
> = Pick<
  RouteConfig<MatchParams, SearchParams, State, Hash, string>,
  'definition' | 'parseParams'
>;

type MatchParams<T extends RouteConfigForParams<{}, {}, unknown, unknown>> =
  T extends RouteConfigForParams<infer X, {}, unknown, unknown> ? X : never;
type SearchParams<T extends RouteConfigForParams<{}, {}, unknown, unknown>> =
  T extends RouteConfigForParams<{}, infer X, unknown, unknown> ? X : never;
type State<T extends RouteConfigForParams<{}, {}, unknown, unknown>> =
  T extends RouteConfigForParams<{}, {}, infer X, unknown> ? X : never;
type Hash<T extends RouteConfigForParams<{}, {}, unknown, unknown>> =
  T extends RouteConfigForParams<{}, {}, unknown, infer X> ? X : never;

// You would think it would be fine to infer all params from a single generic
// and & them together directly but that ends up with a bunch of things ending
// up undefined.
type AllRouteParams<T extends RouteConfigForParams<{}, {}, unknown, unknown>> =
  MatchParams<T> &
    SearchParams<T> &
    State<T> &
    Hash<T> &
    Record<string, undefined>;

export function useRouteParams<
  RouteConfigs extends OneOrMore<
    RouteConfigForParams<{}, {}, unknown, unknown>
  >,
>(
  // This is here to prevent accidental attempts to use `this`
  this: unknown,
  /**
   * Represents the `RouteConfig` you are trying to read data from
   */
  ...routeConfigs: RouteConfigs
): AllRouteParams<RouteConfigs[number]> {
  const location = useLocation();

  // This match will explicitly not be an exact match. If this definition
  // matches, even if it's not exact, then this will pass. This is useful for
  // using a less specific route configuration for shared logic.
  const matchingRoutingConfig: RouteConfigs[number] | undefined =
    routeConfigs.find((routeConfig) =>
      matchPath(location.pathname, {
        path: routeConfig.definition,
      }),
    );
  if (!matchingRoutingConfig) {
    throw new Error(
      `Location "${
        location.pathname
      }" does not match any RouteConfigs ${routeConfigs
        .map((routeConfig) => `"${routeConfig.definition}"`)
        .join(', ')}`,
    );
  }

  return useMemo(
    () => matchingRoutingConfig.parseParams(location),
    [matchingRoutingConfig, location],
  ) as AllRouteParams<RouteConfigs[number]>;
}
