import IconCopy from '@apollo/icons/default/IconCopy.svg';
import IconRun from '@apollo/icons/default/IconRun.svg';
import { ButtonGroup, IconButton, Code as OrbitCode } from '@apollo/orbit';
import classnames from 'classnames';
import copy from 'copy-to-clipboard';
import { DocumentNode, parse, print } from 'graphql';
import _ from 'lodash';
import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { PrismAsync } from 'react-syntax-highlighter';

import { useInternalGraphLinking } from 'src/app/graph/hooks/useInternalGraphLinking';
import { Tooltip } from 'src/components/common/tooltip/Tooltip';
import { prismStudioThemeLight } from 'src/components/graphqlCodeBlock/prismStudioThemeLight';
import { TooltipWithMutableContent } from 'src/components/TooltipWithMutableContent';

import { EventCategory } from '../analytics';

const tryParseGraphql = (
  source: string,
):
  | { type: 'result'; result: DocumentNode }
  | { type: 'error'; error: Error } => {
  try {
    const result = parse(source);
    return { result, type: 'result' };
  } catch (error) {
    return {
      error: error as Error,
      type: 'error',
    };
  }
};

export const Code = ({
  className,
  children,
  analyticsCategory,
  analyticsLabelPrefix,
  hideActionButtons = false,
  inline = true,
}: {
  className: string | undefined;
  children: string;
  analyticsCategory?: EventCategory;
  analyticsLabelPrefix?: string;
  hideActionButtons?: boolean;
  inline?: boolean;
}) => {
  const language = className?.replace('lang-', '');

  const { locationToExplorer } = useInternalGraphLinking();

  if (inline) {
    return <OrbitCode>{children}</OrbitCode>;
  }

  const parsedResult = tryParseGraphql(children);

  return (
    <div className="my-4 flex rounded border border-primary bg-secondary px-4 py-3">
      <PrismAsync
        style={prismStudioThemeLight}
        language={language === 'gql' ? 'graphql' : language}
        PreTag={({ style, ...props }: { style: CSSProperties }) => (
          <pre
            {...props}
            className={classnames('w-full text-sm', {
              'py-1': !hideActionButtons,
            })}
            style={{
              ..._.omit(
                style,
                'fontSize',
                'fontFamily',
                'margin',
                'padding',
                'background',
              ),
            }}
          />
        )}
        CodeTag={({ style, ...props }: { style: CSSProperties }) => (
          <code
            {...props}
            className="w-full rounded"
            style={{
              ..._.omit(style, 'fontFamily'),
              background: 'none',
              whiteSpace: 'pre-wrap',
            }}
          />
        )}
      >
        {children}
      </PrismAsync>
      {!hideActionButtons &&
        language &&
        ['graphql', 'gql'].includes(language) && (
          <ButtonGroup className="self-start">
            <TooltipWithMutableContent
              initialContent="Copy codeblock"
              placement="top"
            >
              {({ setContent, resetContent }) => (
                <IconButton
                  variant="hidden"
                  size="sm"
                  onClick={() => {
                    copy(children);
                    setContent('Copied');
                  }}
                  onMouseEnter={() => resetContent()}
                  data-analytics-label={
                    analyticsLabelPrefix
                      ? `${analyticsLabelPrefix}: Copy codeblock`
                      : undefined
                  }
                  data-analytics-category={analyticsCategory}
                  aria-label="Copy codeblock"
                  icon={<IconCopy />}
                />
              )}
            </TooltipWithMutableContent>
            <Tooltip
              label={
                parsedResult.type === 'result'
                  ? 'Open in Explorer'
                  : 'Invalid graphql - cannot open in Explorer.'
              }
              placement="top"
            >
              {parsedResult.type === 'error' ? (
                <IconButton
                  size="sm"
                  icon={<IconRun />}
                  isDisabled={true}
                  aria-label="Open in Explorer"
                />
              ) : (
                <IconButton
                  size="sm"
                  icon={<IconRun />}
                  as={Link}
                  to={locationToExplorer({
                    document: print(parsedResult.result),
                  })}
                  data-analytics-label={
                    analyticsLabelPrefix
                      ? `${analyticsLabelPrefix}: Open in Explorer`
                      : undefined
                  }
                  data-analytics-category={analyticsCategory}
                  aria-label="Open in Explorer"
                />
              )}
            </Tooltip>
          </ButtonGroup>
        )}
    </div>
  );
};
