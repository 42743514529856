import { LogLevel } from '@amplitude/analytics-types';
import * as Sentry from '@sentry/react';
import { ProviderConfig as LaunchDarklyConfig } from 'launchdarkly-react-client-sdk';
import { parse } from 'query-string';

import { LoadOptions } from 'src/lib/analytics/amplitude/vendor';
import {
  LaunchDarklyClientID,
  launchDarklyConfig,
} from 'src/lib/launchDarkly/config';

enum StripePublicKeys {
  Test = 'pk_test_51KnlNlF5LMzPO0GwZKsMQvQyRGLP6LS8A9zNUwrcaEw1dZwkCsmU7OwS9IkHPhE8pYLxuknXzPdjYdzjNMmOD9jL00FgRbNzJw',
  Prod = 'pk_live_51KnlNlF5LMzPO0Gwymqy4mejkll1UtXGVIc3cnkZXxp2fuVYekfHRzsaTJFCH3JNnS9DAEliKBd00wONRK0vtIa800AmIbkaeF',
}

/**
 * There are currently 3 ways to set the environment that will be used and the order of precedence
 * 1) __prod__, __dev__, and __local__ query params will override the backend URL and set the client name to custonm
 * 2) the URL will be used to determine the environment (for staging and prod, the 3rd way will be ignored)
 * 3) window.studioConfigEnv (set through webpack) (this value will be ignored for prod and staging host names)
 */

export enum StudioConfigEnv {
  Dev = 'dev',
  Staging = 'staging',
  Dev0 = 'dev0',
  Local = 'local',
  Prod = 'prod',
  LocalProd = 'local-prod',
  Test = 'test',
}

interface FrontendConfig<Key extends StudioConfigEnv | 'custom'> {
  env: Key;
  studioUrl: string;
  enablePersistedQueries: boolean;
  segmentKey?: string;
  amplitudeOptions: LoadOptions;
  shouldAppendOperationNameToGraphqlNetworkRequests: boolean;

  // Values linked to the backend
  isProdBackend: boolean;
  engineBackendUrl?: string;
  adminUrl?: string;
  recaptchaSiteKey?: string;
  recurlyPublicKey: string;
  stripePublicKey: StripePublicKeys;
  launchDarkly: LaunchDarklyConfig & { defaultUserID: string };
  apolloClientClientName: string;
}

const studioConfigEnv = window.studioConfigEnv; // eslint-disable-line no-restricted-properties

function getEnv() {
  // #1 __prod__, __dev0__, and __local__ can be
  const parsedQueryParams = parse(window.location.search);
  const [override, ...rest] = [
    StudioConfigEnv.Prod,
    StudioConfigEnv.Dev0,
    StudioConfigEnv.Local,
  ].filter((backend) => {
    if (parsedQueryParams[`__${backend}__`]) {
      throw new Error(
        `unknown value for queryParam __${backend}__, to set it, don't provide a value, just \`?__${backend}__\``,
      );
    }
    return parsedQueryParams[`__${backend}__`] === null;
  });
  if (rest.length > 0) {
    throw new Error(
      `Multiple queryparam env overrides present, only 1 supported`,
    );
  }
  if (override) return { env: override, isCustom: true };

  // #2 if no url envs are present, and `window.studioConfigEnv` isn't set, pick env based on the host
  const isProd = [
    'studio.apollographql.com',
    'sandbox.embed.apollographql.com',
    'explorer.embed.apollographql.com',
    'studio-eu.apollographql.com',
    'apollo-studio.netlify.app',
  ].includes(window.location.hostname);
  const isLocalProd =
    window.location.hostname === 'local-studio.apollographql.com';
  const isStaging =
    window.location.hostname === 'studio-staging.apollographql.com';
  const isDev0 = window.location.hostname === 'studio-dev0.apollographql.com';
  const isDeployPreview = window.location.hostname.match(
    /deploy-preview-\d+--apollo-studio.netlify.app/,
  );
  const isChromatic = !!window.location.hostname.match(/chromatic.com$/);

  if (
    studioConfigEnv &&
    (isProd || isLocalProd || isStaging || isDeployPreview)
  ) {
    Sentry.captureException(
      new Error('`studioConfigEnv` can only be set for localhost'),
    );
  }

  if (isStaging) {
    return { env: StudioConfigEnv.Staging, isCustom: false };
  } else if (isProd) {
    return { env: StudioConfigEnv.Prod, isCustom: false };
  } else if (isLocalProd) {
    return { env: StudioConfigEnv.LocalProd, isCustom: true };
  } else if (isDev0) {
    return { env: StudioConfigEnv.Dev0, isCustom: false };
  } else if (
    (window.location.hostname === 'localhost' && !studioConfigEnv) ||
    isDeployPreview ||
    isChromatic
  ) {
    return { env: StudioConfigEnv.Dev, isCustom: false };
  } else if (window.location.hostname === 'local-test.apollographql.com') {
    return { env: StudioConfigEnv.Dev, isCustom: false };
  }

  // #3 if `window.studioConfigEnv` is present return that env or throw an error if it's unknown
  if (studioConfigEnv) {
    const envOverride = Object.values(StudioConfigEnv).find(
      (v) => v === studioConfigEnv,
    );
    if (!envOverride) throw new Error('unknown env');
    return { env: envOverride, isCustom: false };
  } else {
    Sentry.captureException(
      new Error(
        'Unknown host, all hosts should be handled in runtime.ts getEnv',
      ),
      { extra: { host: window.location.hostname } },
    );
    return { env: StudioConfigEnv.Dev, isCustom: true };
  }
}

// These are all the options for backends to be used, keys that need to match the backend should go here
const backends = {
  dev0: {
    engineBackendUrl: 'https://graphql-dev0.api.apollographql.com',
    recurlyPublicKey: 'ewr1-8PrdqZT1rSXnSFPyQqrapj',
    stripePublicKey: StripePublicKeys.Test,
    recaptchaSiteKey: '6LenWz0aAAAAAIHllOd43cKTv_inp1zt-NFFg47I',
    launchDarkly: {
      ...launchDarklyConfig,
      clientSideID: LaunchDarklyClientID.Dev0,
      defaultUserID: `anon-studio-${StudioConfigEnv.Dev0}-user`,
    },
  },
  staging: {
    engineBackendUrl: 'https://graphql-staging.api.apollographql.com',
    adminUrl: 'https://admin-staging.apollographql.com',
    recaptchaSiteKey: '6LenWz0aAAAAAIHllOd43cKTv_inp1zt-NFFg47I',
    recurlyPublicKey: 'ewr1-8PrdqZT1rSXnSFPyQqrapj',
    stripePublicKey: StripePublicKeys.Test,
    launchDarkly: {
      ...launchDarklyConfig,
      clientSideID: LaunchDarklyClientID.Staging,
      defaultUserID: `anon-studio-${StudioConfigEnv.Staging}-user`,
    },
  },
  prod: {
    engineBackendUrl: 'https://graphql.api.apollographql.com',
    adminUrl: 'https://admin.apollographql.com',
    recurlyPublicKey: 'ewr1-lcJeRCA9drn8pJQCXHEseT',
    stripePublicKey: StripePublicKeys.Prod,
    recaptchaSiteKey: '6LewylUaAAAAACWN7Ly-d7-0jEuiDkibD8URpbkz',
    launchDarkly: {
      ...launchDarklyConfig,
      clientSideID: LaunchDarklyClientID.Prod,
      defaultUserID: `anon-studio-${StudioConfigEnv.Prod}-user`,
    },
  },
  local: {
    engineBackendUrl: 'http://localhost:4000',
    recurlyPublicKey: 'ewr1-8PrdqZT1rSXnSFPyQqrapj',
    stripePublicKey: StripePublicKeys.Test,
    recaptchaSiteKey: '6LenWz0aAAAAAIHllOd43cKTv_inp1zt-NFFg47I',
    launchDarkly: {
      ...launchDarklyConfig,
      clientSideID: LaunchDarklyClientID.Staging,
      defaultUserID: `anon-studio-${StudioConfigEnv.Local}-user`,
    },
  },
  test: {
    recurlyPublicKey: 'not-a-key',
    stripePublicKey: StripePublicKeys.Test,
    launchDarkly: {
      ...launchDarklyConfig,
      clientSideID: LaunchDarklyClientID.Staging,
      defaultUserID: `anon-studio-${StudioConfigEnv.Test}-user`,
    },
  },
};

/* `beacon` transport is an alternative to xhr and fetch transports in browser.
 * It is designed for tracking pixels, and differs from xhr and fetch in that the request
 * is not cancelled when the current page is unloaded. This allows a tracking 'beacon'
 * to succeed, even if the tracked action is an outbound link.
 * This solution is outlined here: https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-1
 * A browser reference to the underlying feature: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
 */

const getAmplitudeOptions = (
  env: StudioConfigEnv = StudioConfigEnv.Dev,
): LoadOptions => {
  switch (env) {
    case StudioConfigEnv.Staging:
      return {
        environment: 'staging',
        disabled: false,
        client: {
          configuration: {
            cookieOptions: {
              sameSite: 'None',
              secure: true,
            },
            defaultTracking: false,
            logLevel: LogLevel.Warn,
            transport: 'beacon',
          },
        },
      };
    case StudioConfigEnv.Prod:
      return {
        environment: 'prod',
        disabled: false,
        client: {
          configuration: {
            cookieOptions: {
              sameSite: 'None',
              secure: true,
            },
            defaultTracking: false,
            logLevel: LogLevel.Error,
            transport: 'beacon',
          },
        },
      };
    default:
      return {
        environment: 'staging',
        disabled: true,
        client: {
          configuration: {
            cookieOptions: {
              sameSite: 'None',
              secure: true,
            },
            defaultTracking: false,
            logLevel: LogLevel.Verbose,
            transport: 'beacon',
          },
        },
      };
  }
};

const configs: {
  [Key in StudioConfigEnv]: Omit<
    FrontendConfig<Key>,
    'env' | 'isProdBackend' | 'apolloClientClientName'
  >;
} = {
  [StudioConfigEnv.Dev]: {
    ...backends.staging,
    amplitudeOptions: getAmplitudeOptions(),
    studioUrl: 'http://localhost:3000',
    enablePersistedQueries: false,
    segmentKey: 'k3sKs4rBF7aSKk659owUEP8LOqaDzcCy',
    shouldAppendOperationNameToGraphqlNetworkRequests: true,
  },
  [StudioConfigEnv.Staging]: {
    ...backends.staging,
    amplitudeOptions: getAmplitudeOptions(StudioConfigEnv.Staging),
    studioUrl: 'https://studio-staging.apollographql.com',
    enablePersistedQueries: true,
    segmentKey: 'Hac7YWK70jnVl1OvYpoBpi39AKHLfcSG',
    shouldAppendOperationNameToGraphqlNetworkRequests: true,
  },
  [StudioConfigEnv.Dev0]: {
    ...backends.dev0,
    amplitudeOptions: getAmplitudeOptions(),
    studioUrl: 'https://studio-dev0.apollographql.com',
    enablePersistedQueries: false,
    segmentKey: 'k3sKs4rBF7aSKk659owUEP8LOqaDzcCy',
    shouldAppendOperationNameToGraphqlNetworkRequests: true,
  },
  [StudioConfigEnv.Local]: {
    ...backends.local,
    studioUrl: 'http://localhost:3000',
    enablePersistedQueries: false,
    segmentKey: 'k3sKs4rBF7aSKk659owUEP8LOqaDzcCy',
    shouldAppendOperationNameToGraphqlNetworkRequests: true,
    amplitudeOptions: getAmplitudeOptions(),
  },
  [StudioConfigEnv.Prod]: {
    ...backends.prod,
    studioUrl: 'https://studio.apollographql.com',
    enablePersistedQueries: true,
    segmentKey: 'xPczztcxJ39mG3oX3wle6XlgpwJ62XAA',
    shouldAppendOperationNameToGraphqlNetworkRequests: false,
    amplitudeOptions: getAmplitudeOptions(StudioConfigEnv.Prod),
  },
  [StudioConfigEnv.LocalProd]: {
    ...backends.prod,
    studioUrl: 'http://localhost:3000',
    enablePersistedQueries: false,
    shouldAppendOperationNameToGraphqlNetworkRequests: true,
    amplitudeOptions: getAmplitudeOptions(),
  },
  [StudioConfigEnv.Test]: {
    ...backends.test,
    studioUrl: 'http://localhost:3000',
    enablePersistedQueries: true,
    shouldAppendOperationNameToGraphqlNetworkRequests: true,
    amplitudeOptions: getAmplitudeOptions(),
  },
};

const { env, isCustom } = getEnv();

const customOverrides: Partial<FrontendConfig<'custom'>> = {
  env: 'custom',
  segmentKey: undefined, // analytics of custom environments aren't useful
  shouldAppendOperationNameToGraphqlNetworkRequests: true,
};

const isProdBackend =
  configs[env].engineBackendUrl === backends.prod.engineBackendUrl;
const isStagingBackend =
  configs[env].engineBackendUrl === backends.staging.engineBackendUrl;

export const runtimeConfig: FrontendConfig<StudioConfigEnv | 'custom'> = {
  env,
  isProdBackend,
  ...configs[env],
  ...(isCustom ? customOverrides : {}),
  apolloClientClientName: `${
    'Cypress' in window
      ? `web-e2e-against-${
          isProdBackend ? 'prod' : isStagingBackend ? 'staging' : 'custom'
        }`
      : `web-${env}`
  }`,
};
