import { parse } from 'graphql/language';
import { intersection } from 'lodash';

import { format } from 'src/lib/format';

import * as ApolloTypes from './graphqlTypes/types';
import { OperationsOverviewQuery } from './graphqlTypes/types';

export interface NormalizedOperationsQueryStats {
  requestsCount?: number | null;
  requestRate?: number | null;
  errorsCount?: number | null;
  errorRate?: number | null;
  errorPercentage?: number | null;
  cacheHitRate?: number | null;
  cacheTTLP50?: number | null;
  serviceTimeP50?: number | null;
  serviceTimeP90?: number | null;
  serviceTimeP95?: number | null;
  serviceTimeP99?: number | null;
  totalDurationMs?: number | null;
  querySize?: number | null;
}

export interface AggregatedOperationsQueryStats {
  requestsCount?: number | null;
  requestRate?: number | null;
  errorsCount?: number | null;
  errorRate?: number | null;
  errorPercentage?: number | null;
  serviceTimeP95?: number | null;
}

export interface NormalizedFieldsFieldUsage {
  alphabetically?: number | null;
  requestsCount?: number | null;
  requestRate?: number | null;
  executions?: number | null;
  errorCount?: number | null;
  errorPercentage?: number | null;
  errorCountPerMin?: number | null;
  p50LatencyMs?: number | null;
  p90LatencyMs?: number | null;
  p95LatencyMs?: number | null;
  p99LatencyMs?: number | null;
}

interface ConsolidatedQueryStats {
  requestsCount: number[];
  errorsCount: number[];
}

export interface QueryStatsDurationHistogram {
  totalCount?: number;
  serviceTimeP50?: number | null;
  serviceTimeP90?: number | null;
  serviceTimeP95?: number | null;
  serviceTimeP99?: number | null;
  totalDurationMs?: number;
}

export interface QueryStatsInput {
  name?: string | null;
  signature?: string | null;
  totalLatencyHistogram?: QueryStatsDurationHistogram;
  cachedHistogram?: QueryStatsDurationHistogram;
  uncachedHistogram?: QueryStatsDurationHistogram;
  cacheTtlHistogram?: QueryStatsDurationHistogram;
  totalRequestCount?: number;
  requestsWithErrorsCount?: number;
  cachedRequestsCount?: number;
  uncachedRequestsCount?: number;
  registeredOperationCount?: number;
}

export interface FieldStatsInput {
  id?: string;
  parentType?: string;
  fieldName?: string;
  name?: string | null;
  signature?: string | null;
  referencingOperationCount?: number;
  totalLatencyHistogram?: QueryStatsDurationHistogram;
  cachedHistogram?: QueryStatsDurationHistogram;
  uncachedHistogram?: QueryStatsDurationHistogram;
  cacheTtlHistogram?: QueryStatsDurationHistogram;
  totalRequestCount?: number;
  requestsWithErrorsCount?: number;
  cachedRequestsCount?: number;
  uncachedRequestsCount?: number;
  registeredOperationCount?: number;
}

const metricConfig = {
  allOperationsMetrics: {
    alphabetically: 'Alphabetically',
    requestsCount: 'Total Requests', // totalLatencyHistogram.totalCount
    requestRate: 'Request Rate', // totalLatencyHistogram.totalCount / totalMinutes (rpm)
    errorsCount: 'Total Errors', // requestsWithErrorsCount
    errorRate: 'Error Rate', // requestsWithErrorsCount / totalMinutes (rpm)
    errorPercentage: 'Error Percentage', // requestsWithErrorsCount / (uncachedLatencyHistogram.totalCount || 1) (%)  avoid 0 / 0 = NaN case)
    cacheHitRate: 'Cache Hit (%)', // totalCacheCount / (totalLatencyHistogram.totalCount || 1) avoid 0 / 0 = NaN case)
    cacheTTLP50: 'p50 Cache TTL',
    serviceTimeP50: 'p50 Service Time',
    serviceTimeP90: 'p90 Service Time',
    serviceTimeP95: 'p95 Service Time',
    serviceTimeP99: 'p99 Service Time',
    totalDurationMs: 'Total Duration',
    querySize: 'Signature Bytes',

    // new top level headings, for when we have a nested menu component implemented
    // errors: 'Errors',
    // cacheHits: 'Cache Hits',
    // pxxCacheAndServices: 'pXX Cache & Services',
    // totalDurationMs: 'Total Duration',
    // querySize: 'Signature Size',
  },
  // Similar to `allOperationsFilters` without `allOperations` as an option.
  newAllOperationsFilters: {
    queries: 'Queries',
    mutations: 'Mutations',
    subscriptions: 'Subscriptions',
    unregistered: 'Unregistered',
    unnamed: 'Unnamed',
  },
  allOperationsFilters: {
    allOperations: 'All Operations',
    queries: 'Queries',
    mutations: 'Mutations',
    subscriptions: 'Subscriptions',
    unregistered: 'Unregistered operations',
    unnamed: 'Unnamed operations',
  },
  allFieldsMetrics: {
    alphabetically: 'Alphabetically',
    requestsCount: 'Total Requests',
    requestRate: 'Request Rate',
    executions: 'Executions',
    errorCount: 'Total Errors',
    errorPercentage: 'Error %',
    errorCountPerMin: 'Error Rate',
    p50LatencyMs: 'p50 Latency',
    p90LatencyMs: 'p90 Latency',
    p95LatencyMs: 'p95 Latency',
    p99LatencyMs: 'p99 Latency',
  },
  allFieldsFilters: {
    used: 'Used',
    unused: 'Unused',
    deprecated: 'Deprecated',
    nonDeprecated: 'Non-deprecated',
  },
  allSchemaCoordinateFieldMetrics: {
    alphabetically: 'Alphabetically',
    requestsCount: 'Total Requests',
    requestRate: 'Request Rate',
  },
  percentileMap: {
    serviceTimeP50: 0.5,
    serviceTimeP90: 0.9,
    serviceTimeP95: 0.95,
    serviceTimeP99: 0.99,
  },
};

export type NormalizedMetricsField =
  keyof typeof metricConfig.allOperationsMetrics;
export type NormalizedMetricsFilterField =
  keyof typeof metricConfig.allOperationsFilters;
export type NewNormalizedMetricsFilterField =
  keyof typeof metricConfig.newAllOperationsFilters;

export const allNormalizedMetricsFields = Object.keys(
  metricConfig.allOperationsMetrics,
) as MinLengthArray<2, NormalizedMetricsField>;
export const allNormalizedMetricsFilterFields = Object.keys(
  metricConfig.allOperationsFilters,
) as MinLengthArray<1, NormalizedMetricsFilterField>;

export type NormalizedFieldsMetricsField =
  keyof typeof metricConfig.allFieldsMetrics;
export type NormalizedSchemaCoordinateFieldMetricsField =
  keyof typeof metricConfig.allSchemaCoordinateFieldMetrics;
export type NormalizedFieldsMetricsFilterField =
  keyof typeof metricConfig.allFieldsFilters;

export type SparklinesQueryStats =
  | NonNullable<
      NonNullable<OperationsOverviewQuery['service']>['sparklines']
    >['queryStats']
  | undefined;

export const allNormalizedFieldsMetricsFields = Object.keys(
  metricConfig.allFieldsMetrics,
) as MinLengthArray<1, NormalizedFieldsMetricsField>;
export const allNormalizedFieldsMetricsFilterFields = Object.keys(
  metricConfig.allFieldsFilters,
) as MinLengthArray<1, NormalizedFieldsMetricsFilterField>;

export function isValidOperationsMetricsField(
  metricsField?: string,
): metricsField is NormalizedMetricsField {
  return (
    metricsField !== undefined &&
    Object.keys(metricConfig.allOperationsMetrics).includes(metricsField)
  );
}

export function isValidOperationsMetricsFilterField(
  metricsFilterField?: string,
): metricsFilterField is NormalizedMetricsFilterField {
  return (
    metricsFilterField !== undefined &&
    Object.keys(metricConfig.allOperationsFilters).includes(metricsFilterField)
  );
}

export function isValidOperationsMetricsFilterFields(
  metricsFilterFields?: string[],
): metricsFilterFields is NewNormalizedMetricsFilterField[] {
  return (
    intersection(
      Object.keys(metricConfig.allOperationsFilters),
      metricsFilterFields ?? [],
    ).length > 0
  );
}

export function isValidFieldsMetricsField(
  metricsField?: string,
): metricsField is NormalizedFieldsMetricsField {
  return (
    metricsField !== undefined &&
    Object.keys(metricConfig.allFieldsMetrics).includes(metricsField)
  );
}

export function isValidFieldsMetricsFilterField(
  metricsFilterField?: string[],
): metricsFilterField is NormalizedFieldsMetricsFilterField[] {
  return (
    intersection(
      Object.keys(metricConfig.allFieldsFilters),
      metricsFilterField ?? [],
    ).length > 0
  );
}

export function isLatencySort(sortMetric?: NormalizedFieldsMetricsField) {
  return [
    'p50LatencyMs',
    'p90LatencyMs',
    'p95LatencyMs',
    'p99LatencyMs',
  ].includes(sortMetric ?? '');
}

export function isErrorsSort(sortMetric?: NormalizedFieldsMetricsField) {
  return ['errorCount', 'errorPercentage', 'errorCountPerMin'].includes(
    sortMetric ?? '',
  );
}

const formatMetrics = (key: string, data?: number | null) => {
  switch (key) {
    case 'serviceTimeP50':
    case 'serviceTimeP90':
    case 'serviceTimeP95':
    case 'serviceTimeP99':
    case 'p50LatencyMs':
    case 'p90LatencyMs':
    case 'p95LatencyMs':
    case 'p99LatencyMs':
    case 'totalDurationMs':
    case 'cacheTTLP50':
      return format.ms(data, 1, false);
    case 'uniqueFields':
      return format.metric(data, '0a');
    case 'cacheHitRate':
    case 'errorPercentage':
      return format.percent(data);
    case 'errorCountPerMin':
    case 'requestRate':
    case 'errorRate':
      return `${format.requests(data)}rpm`;
    case 'errorsCount':
    case 'requestsCount':
    case 'executions':
    case 'alphabetically':
      return format.metric(data, '0[.]0a');
    case 'querySize':
      return format.number(data, '0[.]00b');
    default:
      return format.number(data);
  }
};

export const isMutation = (signature: string): boolean => {
  // Best effort check if a given signature represents a mutation
  const maybeMutation = Boolean(signature.match(/(^|})mutation\b/m));
  if (!maybeMutation) {
    return false;
  }

  // If our "best effort" regex above matches then double check by parsing the
  // signature. Do this in multiple steps since parsing a signature is more
  // expensive than evaluating a regex
  let document;
  try {
    document = parse(signature);
  } catch (error) {
    // If it's not a valid document then assume it's not a mutation
    return false;
  }

  return document.definitions.some(
    (definition) =>
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'mutation',
  );
};

export const isSubscription = (signature: string): boolean => {
  // Best effort check if a given signature represents a subscription
  const maybeSubscription = Boolean(signature.match(/(^|})subscription\b/m));
  if (!maybeSubscription) {
    return false;
  }

  // If our "best effort" regex above matches then double check by parsing the
  // signature. Do this in multiple steps since parsing a signature is more
  // expensive than evaluating a regex
  let document;
  try {
    document = parse(signature);
  } catch (error) {
    // If it's not a valid document then assume it's not a subscription
    return false;
  }

  return document.definitions.some(
    (definition) =>
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription',
  );
};

/**
 * Best effort to determine the operation type from the signature.
 * This would ideally be something we could query for through GraphQL.
 * @param signature of an operation signature to evaluate
 */
export const operationTypeFromSignature = (
  signature: string,
): ApolloTypes.OperationType => {
  return isSubscription(signature)
    ? ApolloTypes.OperationType.SUBSCRIPTION
    : isMutation(signature)
      ? ApolloTypes.OperationType.MUTATION
      : ApolloTypes.OperationType.QUERY;
};

export const SUBSCRIPTION_OPERATION_TYPE = 'subscription';
export const MUTATION_OPERATION_TYPE = 'mutation';

export const filterMetrics = <QS extends QueryStatsInput>(
  stats: QS[],
  key: NormalizedMetricsFilterField,
) => {
  switch (key) {
    case 'allOperations':
      return stats;
    case 'queries':
      return stats.filter(
        ({ signature }) =>
          !(
            (signature && isMutation(signature)) ||
            (signature && isSubscription(signature))
          ),
      );
    case 'mutations':
      return stats.filter(({ signature }) =>
        Boolean(signature && isMutation(signature)),
      );
    case 'subscriptions':
      return stats.filter(({ signature }) =>
        Boolean(signature && isSubscription(signature)),
      );
    case 'unregistered':
      return stats.filter(
        ({ totalLatencyHistogram, registeredOperationCount }) =>
          Boolean(
            totalLatencyHistogram &&
              typeof totalLatencyHistogram.totalCount === 'number' &&
              typeof registeredOperationCount === 'number' &&
              totalLatencyHistogram.totalCount - registeredOperationCount > 0,
          ),
      );
    case 'unnamed':
      return stats.filter(({ name }) => !name);
    default:
      return stats;
  }
};

const aggregateStats = (
  stats: SparklinesQueryStats,
  totalMinutes: number,
): AggregatedOperationsQueryStats => {
  const consolidated: ConsolidatedQueryStats = {
    requestsCount: [],
    errorsCount: [],
  };

  stats?.forEach((stat) => {
    consolidated.requestsCount.push(
      stat.metrics.totalLatencyHistogram.totalCount,
    );
    consolidated.errorsCount.push(stat.metrics.requestsWithErrorsCount);
  });

  const totalCount = consolidated.requestsCount.reduce((a, b) => a + b, 0);
  const totalErrors = consolidated.errorsCount.reduce((a, b) => a + b, 0);

  return {
    requestsCount: totalCount,
    requestRate: totalCount / totalMinutes,
    errorsCount: totalErrors,
    errorRate: totalErrors / totalMinutes,
    errorPercentage: totalErrors / (totalCount || 1),
  };
};

/**
 * Normalizes a set of metrics returned by GraphQL (currently supports a flattened version of QueryStatsRecord).
 * Also performs calculations to derive additional information used by various parts of the app.
 * @param stats - It is expected that we flatten QueryStatsRecord.metrics together with QueryStatsRecord.group
 * to combine them into a single object.
 * */
const calculateMetrics = (
  // Just noting that this function probably will not work on both query stats and
  // field stats in the long-run without some additional Typescript-foo.
  // Since it was designed for query stats, we now return a NormalizedOperationsQueryStats result.
  stats: QueryStatsInput | FieldStatsInput,
  totalMinutes: number,
): NonNullableFields<Required<NormalizedOperationsQueryStats>> => {
  const totalCount =
    stats.totalRequestCount || stats.totalLatencyHistogram?.totalCount || 0;
  const uncachedCount =
    stats.uncachedRequestsCount || stats.uncachedHistogram?.totalCount || 0;
  const cachedCount =
    stats.cachedRequestsCount || stats.cachedHistogram?.totalCount || 0;

  return {
    requestsCount: totalCount,
    requestRate: totalCount / totalMinutes,
    errorsCount: stats.requestsWithErrorsCount || 0,
    errorRate: (stats.requestsWithErrorsCount || 0) / totalMinutes,
    errorPercentage:
      (stats.requestsWithErrorsCount || 0) / (totalCount || uncachedCount || 1),
    cacheHitRate: cachedCount / (totalCount || 1),
    cacheTTLP50:
      (stats.cacheTtlHistogram && stats.cacheTtlHistogram.serviceTimeP50) || 0,
    querySize: stats.signature
      ? new Blob([stats.signature], {
          type: 'text/plain;charset=UTF-8',
        }).size
      : 0,
    serviceTimeP50: stats.totalLatencyHistogram?.serviceTimeP50 || 0,
    serviceTimeP90: stats.totalLatencyHistogram?.serviceTimeP90 || 0,
    serviceTimeP95: stats.totalLatencyHistogram?.serviceTimeP95 || 0,
    serviceTimeP99: stats.totalLatencyHistogram?.serviceTimeP99 || 0,
    totalDurationMs: stats.totalLatencyHistogram?.totalDurationMs || 0,
  };
};

export const fromGQLOperationsListItemToOperationsListItem = (
  operation: ApolloTypes.OperationInsightsListItem,
) => ({
  id: operation.id,
  name: operation.displayName,
  type: operation.type,
  requestsCount: operation.requestCount,
  requestRate: operation.requestCountPerMin,
  errorsCount: operation.errorCount,
  errorRate: operation.errorCountPerMin,
  errorPercentage: operation.errorPercentage,
  cacheHitRate: operation.cacheHitRate,
  cacheTTLP50: operation.cacheTtlP50Ms,
  serviceTimeP50: operation.serviceTimeP50Ms,
  serviceTimeP90: operation.serviceTimeP90Ms,
  serviceTimeP95: operation.serviceTimeP95Ms,
  serviceTimeP99: operation.serviceTimeP99Ms,
  totalDurationMs: operation.totalDurationMs,
  querySize: operation.signatureBytes,
});

export const fromGQLFieldListItemtoFieldListItem = (
  field: ApolloTypes.FieldsListQuery_graph_variant_fieldInsightsList_fields,
) => ({
  name: `${field?.parentType || 'undefined'}.${
    field?.fieldName || 'undefined'
  }`,
  requestsCount: field?.referencingOperationCount,
  requestRate: field?.referencingOperationCountPerMin,
  executions: field?.estimatedExecutionCount,
  errorCount: field?.errorCount,
  errorCountPerMin: field?.errorCountPerMin,
  errorPercentage: field?.errorPercentage,
  p50LatencyMs: field?.p50LatencyMs,
  p90LatencyMs: field?.p90LatencyMs,
  p95LatencyMs: field?.p95LatencyMs,
  p99LatencyMs: field?.p99LatencyMs,
});

export { formatMetrics, metricConfig, aggregateStats, calculateMetrics };
