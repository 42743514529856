import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Navigate, Route, Routes, useParams } from 'react-router-dom-v5-compat';

import { LoggedInRoute } from 'src/components/common/authenticatedRoutes/AuthenticatedRoutes';
import { ErrorBoundary } from 'src/components/common/errorBoundary/ErrorBoundary';
import { Loading } from 'src/components/common/loading/Loading';
import { NotFound } from 'src/components/common/notFound/NotFound';
import { Toasts } from 'src/components/toast/Toast';
import { useInitializeClickAnalytics } from 'src/hooks/useInitializeClickAnalytics';
import { useTelemetryInitializer } from 'src/hooks/useTelemetryInitializer';
import { useSyncAmplitudeForEmbedded } from 'src/lib/analytics/amplitude/hooks/useSyncAmplitudeForEmbedded';
import Config from 'src/lib/config';
import { BackendRouter } from 'src/lib/routers';
import { listenForKonami } from 'src/templates/index/konami';

import { EmbedLogoutPage } from '../account/logoutPage/EmbedLogoutPage';
import { LogoutPage } from '../account/logoutPage/LogoutPage';
import { AppLayout } from '../appLayout/AppLayout';
import { BuildInfoPage } from '../buildInfo/buildInfoPage/BuildInfoPage';
import { EmailPreferencesPage } from '../emailPreferences/emailPreferencesPage/EmailPreferencesPage';
import { EmbedAuthenticator } from '../embeddableExplorer/EmbedAuthenticator';
import { EmbeddableExplorer } from '../embeddableExplorer/EmbeddableExplorer';
import { isEmbeddableExplorerRoute } from '../embeddableExplorer/isEmbeddableExplorerRoute';
import { isEmbeddableSandboxRoute } from '../embeddableSandbox/isEmbeddableSandboxRoute';
import { PreflightScriptOAuthTokenFetcher } from '../graph/explorerPage/explorerSettings/PreflightScriptOAuthConfirmationModal';
import {
  atlasExplorerRouteConfig,
  sandboxExplorerRouteConfig,
  sandboxGraphRouteConfig,
} from '../graph/routes';
import {
  Onboarding,
  ownedRoutes as onboardingOwnedRoutes,
} from '../onboarding/Onboarding';
import * as routes from '../onboarding/routes';
import * as sharedRoutes from '../shared/routes';

import {
  BuildRoute,
  NotFoundRoute,
  RootRoute,
  userEmailPreferencesRoute,
} from './routes';
import { ServiceWorkerRefreshPrompts } from './ServiceWorkerRefreshPrompts';

const EmbeddableSandbox = React.lazy(async () => ({
  default: (
    await import(
      /* webpackChunkName: "EmbeddableSandbox" */ '../embeddableSandbox/EmbeddableSandbox'
    )
  ).EmbeddableSandbox,
}));

const UserProfileSurvey = React.lazy(async () => ({
  default: (
    await import(
      /* webpackChunkName: "UserProfileSurvey" */ '../onboarding/views/userProfileSurvey/UserProfileSurvey'
    )
  ).UserProfileSurvey,
}));

function LoginWithProvider() {
  const { provider } = useParams();
  BackendRouter.go('LoginWithProviderAndCallback', {
    provider,
    callbackUrl: Config.absoluteUrl,
  });
  return null;
}

/*
 * Studio – the app!
 *
 * Authentication is enforced on LoggedInRoute's, Route's are public,
 * but will need to be placed above
 * <LoggedInRoute path={RootRoute.definition} component={AppLayout} />
 * since it matches everything
 *
 * If you visit a protect route while logged out, you'll be forwarded to /login.
 * If you visited /login while logged in, you'll be sent to the root url /.
 */
export const RoutedApp = Sentry.withProfiler(() => {
  useEffect(() => {
    document.addEventListener('keydown', listenForKonami);
    return () => document.removeEventListener('keydown', listenForKonami);
  }, []);

  useInitializeClickAnalytics();

  const isEmbeddableExplorer = isEmbeddableExplorerRoute();
  const isEmbeddableSandbox = isEmbeddableSandboxRoute();

  useSyncAmplitudeForEmbedded();
  useTelemetryInitializer(isEmbeddableExplorer || isEmbeddableSandbox);

  const location = useLocation();

  return (
    <ErrorBoundary>
      <ServiceWorkerRefreshPrompts />
      <React.Suspense fallback={<Loading />}>
        {isEmbeddableExplorer ? (
          <Routes>
            <Route
              path={`${routes.embedLogoutRouteConfig.definition}/*`}
              Component={EmbedLogoutPage}
            />
            <Route
              path={`${sharedRoutes.preflightOAuth2RedirectRoute.definition}/*`}
              Component={PreflightScriptOAuthTokenFetcher}
            />
            <Route
              path={`${RootRoute.definition}*`}
              element={<EmbeddableExplorer />}
            />
          </Routes>
        ) : isEmbeddableSandbox ? (
          <Routes>
            <Route
              path={`${routes.embedLogoutRouteConfig.definition}/*`}
              Component={EmbedLogoutPage}
            />
            <Route
              path={`${sharedRoutes.preflightOAuth2RedirectRoute.definition}/*`}
              Component={PreflightScriptOAuthTokenFetcher}
            />
            <Route
              path={`${sandboxGraphRouteConfig.definition}/*`}
              Component={EmbeddableSandbox}
            />
          </Routes>
        ) : (
          <Routes>
            <Route
              path={`${sharedRoutes.preflightOAuth2RedirectRoute.definition}/*`}
              Component={PreflightScriptOAuthTokenFetcher}
            />
            <Route
              path={`${routes.logoutRouteConfig.definition}/*`}
              Component={LogoutPage}
            />
            <Route
              path={`${NotFoundRoute.definition}/*`}
              Component={NotFound}
            />
            <Route
              path={routes.loginWithProvider.definition}
              Component={LoginWithProvider}
            />
            {onboardingOwnedRoutes.map((definition) => (
              <Route
                key={definition}
                path={`${definition}/*`}
                Component={Onboarding}
              />
            ))}
            <Route
              path={`${routes.userProfileSurvey.definition}/*`}
              element={<LoggedInRoute Component={UserProfileSurvey} />}
            />
            <Route
              path={`${BuildRoute.definition}/*`}
              Component={BuildInfoPage}
            />
            <Route
              path={`${userEmailPreferencesRoute.definition}/*`}
              Component={EmailPreferencesPage}
            />
            <Route
              path={`${routes.embedAuthenticationRouteConfig.definition}/*`}
              element={
                <>
                  <EmbedAuthenticator />
                  <Toasts />
                </>
              }
            />
            <Route
              path={routes.sandboxLanding.definition}
              element={
                <Navigate
                  replace={true}
                  to={{
                    ...sandboxExplorerRouteConfig.location({}),
                    search: location.search,
                  }}
                />
              }
            />
            {[
              // List of app routes that do not require the user to be logged in
              `${sandboxGraphRouteConfig.definition}/*`,
              `${atlasExplorerRouteConfig.definition}/*`,
              '/public/*',
            ].map((definition) => (
              <Route key={definition} path={definition} Component={AppLayout} />
            ))}

            <Route
              path={`${RootRoute.definition}*`}
              element={<LoggedInRoute Component={AppLayout} />}
            />
          </Routes>
        )}
      </React.Suspense>
    </ErrorBoundary>
  );
});
