import { gql, useQuery } from '@apollo/client';
import { partition } from 'lodash';
import { useEffect, useMemo } from 'react';

import { useGraphVisibility } from 'src/app/graph/hooks/useGraphVisibility';
import { ampli } from 'src/lib/analytics/amplitude/vendor';
import { Config } from 'src/lib/config/config';

import { Analytics } from '../lib/analytics';
import { ignorePermissionsErrors } from '../lib/apollo/catchErrors';
import { appLinkContext } from '../lib/apollo/link';
import {
  AccountIdentityAccountFragment as AccountIdentityAccountFragmentType,
  UI__IdentityQuery,
  UI__IdentityQueryVariables,
  UI__IdentityQuery_me,
  UI__IdentityQuery_me_User,
  UI__IdentityQuery_me_User_memberships,
} from '../lib/graphqlTypes/types';

import { useCurrentAccountId } from './useCurrentAccountId';

const UserIdentityAnalyticsFragment = gql`
  fragment UserIdentityAnalyticsFragment on User {
    id
    fullName
    email
    avatarUrl
    createdAt
  }
`;

export const AccountIdentityAccountFragment = gql`
  fragment AccountIdentityAccountFragment on Account {
    id
    internalID
    name
    avatarUrl
    createdAt
    isLocked
    currentPlan {
      id
      tier
    }
  }
`;

export const identityQuery = gql<UI__IdentityQuery, UI__IdentityQueryVariables>`
  query UI__IdentityQuery {
    me {
      id
      ... on User {
        id
        email
        emailVerified
        fullName
        canUpdateEmail
        canUpdateFullName
        acceptedPrivacyPolicyAt
        type
        memberships {
          role: permission
          account {
            id
            ...AccountIdentityAccountFragment
          }
          user {
            id
          }
        }
        ...UserIdentityAnalyticsFragment
      }
    }
  }
  ${UserIdentityAnalyticsFragment}
  ${AccountIdentityAccountFragment}
`;

export const chooseDefaultAccountId = (me: UI__IdentityQuery_me) => {
  // Don't default an org to a locked account unless there are no unlocked account
  if (me.__typename === 'User') {
    const [unlockedOrgs = [], lockedOrgs = []] = partition(
      me.memberships
        .map(({ account }) => account)
        .sort((a, b) => (a.name > b.name ? 1 : -1)),
      ['isLocked', false],
    );
    return (
      (unlockedOrgs.length
        ? unlockedOrgs[0]?.id
        : lockedOrgs.length
          ? lockedOrgs[0]?.id
          : null) || null
    );
  }

  return null;
};

const findOwnAccount = (
  me: UI__IdentityQuery_me,
  accountId: string,
): AccountIdentityAccountFragmentType | null => {
  let account = null;

  if (me.__typename === 'User') {
    const memberships: Array<UI__IdentityQuery_me_User_memberships> =
      me.memberships;
    account =
      memberships.find((membership) => membership.account.id === accountId)
        ?.account || null;
  }

  return account;
};

export const isUserIdentity = (user: {
  __typename: string;
}): user is UI__IdentityQuery_me_User => user.__typename === 'User';

// we keep track of `lastSeenUserId` at file scope to prevent re-running analytics side-effects
// in the `useIdentity` hook.
let lastSeenUserId: string | null = null;

/**
 * useIdentity() is a React Hook that provides the user's current identity,
 * which is comprised of 4 things:
 * `me`: The "me" value from a GraphQL query which describes a user.
 * `meLoading`: true if the me query is still loading.
 * This hook has several side-effects:
 * - setting Apollo local state `currentAccountId` to a reasonable default if it is not yet set,
 * - setting Analytics context for the current user / account
 * - setting the Sentry context for the current user.
 */
type IdentityHook = {
  me: UI__IdentityQuery_me_User | null;
  isUser: boolean;
  meLoading: boolean;
  error: Error | undefined;
  memberships: Array<UI__IdentityQuery_me_User_memberships>;
};

export const useIdentity = (): IdentityHook => {
  const {
    data: { me } = {} as UI__IdentityQuery,
    loading: meLoading,
    error,
  } = useQuery(identityQuery, {
    context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
    variables: {},
    errorPolicy: 'all',
  });
  const [currentAccountId, setCurrentAccountId] = useCurrentAccountId();
  const graphVisibility = useGraphVisibility();

  const activeAccountId = useMemo(() => {
    if (currentAccountId) return currentAccountId;
    if (me && graphVisibility !== 'public') {
      return chooseDefaultAccountId(me);
    }
  }, [currentAccountId, graphVisibility, me]);

  useEffect(() => {
    if (currentAccountId !== activeAccountId && activeAccountId) {
      setCurrentAccountId(activeAccountId);
    }
  }, [activeAccountId, currentAccountId, setCurrentAccountId]);

  const myAccount =
    (me && activeAccountId && findOwnAccount(me, activeAccountId)) || null;

  useEffect(() => {
    if (me && me.id !== lastSeenUserId) {
      lastSeenUserId = me.id;
      if (isUserIdentity(me)) {
        if (Analytics.isAvailable()) {
          Analytics.identify(me);
          if (myAccount) Analytics.group(myAccount);
        }
        if (ampli.isLoaded) {
          ampli.identify(
            me.id,
            {
              'Active Org Id': myAccount?.internalID,
              'Email Verified': me.emailVerified,
              Email: me?.email || undefined,
              'Full Name': me.fullName,
            },
            {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              app_version: Config.gitCommitHash,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              user_id: me.id,
            },
          );
        }
      }
    }
  }, [me, myAccount]);

  const isUser = me?.__typename === 'User';
  const identity = me as UI__IdentityQuery_me_User;

  return {
    me: identity,
    memberships: isUser ? identity.memberships : [],
    isUser,
    meLoading,
    error,
  };
};
