import React, { useEffect } from 'react';

import { isEmbeddableExplorerRoute } from 'src/app/embeddableExplorer/isEmbeddableExplorerRoute';
import { login as loginRoute } from 'src/app/onboarding/routes/routes';
import { useIsCheckedRoute } from 'src/hooks/useIsCheckedRoute';
import { shouldOptOutOfAmplitude } from 'src/lib/analytics/amplitude/helpers/shouldOptOutOfAmplitude';
import { ampli } from 'src/lib/analytics/amplitude/vendor';
import { useLDFlag } from 'src/lib/launchDarkly';

type amplitudeFlagConfig = {
  trackingEnabled: boolean;
  trackEmbeddedUsers: boolean;
};

type ReactChildren = {
  children: React.ReactElement;
};

const useAmplitudeConfig = (): amplitudeFlagConfig => {
  const trackingEnabled = useLDFlag('studio-amplitude-tracking-enabled');

  const trackEmbeddedUsers = useLDFlag('betelgeuse-amplitude-in-embedded');

  return {
    trackingEnabled,
    trackEmbeddedUsers,
  };
};

export const AmplitudeProvider = ({ children }: ReactChildren) => {
  const amplitudeConfiguration = useAmplitudeConfig();
  const excludeRouteFromTracking =
    useIsCheckedRoute(loginRoute.definition) || isEmbeddableExplorerRoute();

  useEffect(() => {
    const shouldOptOut = shouldOptOutOfAmplitude({
      excludeRouteFromTracking,
      amplitudeConfiguration,
    });
    ampli.client.setOptOut(shouldOptOut);
  }, [excludeRouteFromTracking, amplitudeConfiguration]);

  return children;
};
