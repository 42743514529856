import { ExternalRouter } from 'src/lib/external-router';

import Config from '../config';

const { docsUrl } = Config;

export const DocsRouter = new ExternalRouter(docsUrl, {
  Home: '',

  // GraphOS
  StudioHome: 'graphos',
  GraphOS: 'graphos/',

  ApiKeys: 'graphos/api-keys/',
  SchemaValidation: 'graphos/delivery/schema-checks',
  SchemaValidationPotentiallyBreakingChanges:
    'graphos/delivery/schema-checks/#potentially-breaking-changes',
  SchemaOverridingFlaggedChanges:
    'graphos/delivery/schema-checks/#overriding-flagged-changes',
  IntegrateChecksWithGitHub:
    '/graphos/delivery/schema-checks/#integrating-with-github',
  LinterRules: `graphos/delivery/linter-rules`,
  LinterSetup: `graphos/delivery/schema-linter`,
  LinterConfiguration: `graphos/delivery/schema-linter/#configuring-the-linter`,
  LinterCompositionRules: `graphos/delivery/linter-rules/#composition-hints`,
  CustomChecks: `graphos/platform/schema-management/checks/custom`,

  FederationComposition: `federation/federated-types/composition/`,
  FederationQuickstart: `federation/quickstart/`,

  EntepriseFeatures: 'graphos/enterprise/',

  Explorer: 'graphos/explorer',
  ExplorerConnectingWithHash:
    'graphos/explorer/connecting-authenticating#:hash',
  EmbeddedExplorer: 'graphos/explorer/embed-explorer/',
  EmbeddedExplorerOptions: 'graphos/explorer/embed-explorer/#options',
  EmbeddedSandbox: 'graphos/explorer/sandbox/#embedding-sandbox',

  AddAVariant: 'graphos/graphs/federated-graphs/#adding-a-variant',
  PublicGraphs: 'graphos/graphs/studio-features/#public-variants',

  AuditLogs: 'graphos/org/audit-log',
  UserRoles: 'graphos/org/members/',
  GraphRoles: 'graphos/org/members/#graph-specific-member-roles',
  GraphApiKeyRoles: 'graphos/org/members/#graph-api-key-roles',
  OrgRoles: 'graphos/org/members/#organization-wide-member-roles',
  OrgPlans: 'graphos/org/plans/',
  EnterpriseTrialOrgPlan: 'graphos/org/plans/#enterprise-trials',

  PlatformAPI: 'graphos/platform-api/',

  UsageReporting: 'graphos/metrics',
  UsageReportingWithHash: 'graphos/metrics#:hash',
  ClientAwareness: 'graphos/metrics/client-awareness',
  DatadogIntegration: 'graphos/metrics/datadog-integration',
  FieldsUsage: 'graphos/metrics/field-usage/',
  FieldUsageMetricsAvailability:
    'graphos/metrics/field-usage/#field-usage-metrics-availability',
  NotificationSetup:
    'graphos/platform/insights/notifications/performance-alerts#:hash',
  SchemaProposalNotificationsFormat:
    'graphos/metrics/notifications/schema-proposal-integration/#webhook-format',
  OperationSignatures: 'graphos/metrics/operation-signatures',
  OperationMetrics: 'graphos/metrics/sending-operation-metrics',

  GettingStarted: 'graphos/quickstart/cloud/#:hash',
  GetToKnowYourSupergraph:
    'graphos/quickstart/cloud/#3-learn-about-your-variant',

  CloudRoutingOverview: 'graphos/routing/cloud/',
  GraphOSCloudRouting: 'graphos/routing/cloud/#:hash',
  CloudRouterSecrets: 'graphos/routing/cloud/configuration/#managing-secrets',
  CloudRouterYAMLConfig:
    'graphos/routing/cloud/configuration/#router-configuration-yaml',

  PersistedQueries: 'graphos/operations/persisted-queries',

  // Apollo Server
  ApolloServerCors: 'apollo-server/api/apollo-server/#cors',
  UsageReportingPluginWithHash:
    'apollo-server/api/plugin/usage-reporting/#:hash',
  MonitoringAndMetrics: 'apollo-server/monitoring/metrics',
  ApolloServerCaching: 'apollo-server/performance/caching',

  // Federation
  Federation: 'federation/',
  SecureSubgraphs:
    'federation/building-supergraphs/subgraphs-overview#securing-your-subgraphs',
  Entities: 'federation/entities/',
  ManagedFederation: 'federation/managed-federation/setup/',
  FederationQueryPlans: 'federation/query-plans',
  FederationChangelog: 'federation/federation-versions/',
  FederationMetrics: 'federation/metrics',

  // Rover
  Rover: 'rover/',
  RoverCiCdSetup: 'rover/ci-cd/',
  RoverDev: 'rover/commands/dev',
  RoverConfigure: 'rover/configuring',
  RoverGettingStarted: 'rover/getting-started',

  // Router
  Router: 'router/',
  RouterTelemetry: 'router/configuration/telemetry/overview',
  DeferSupport: 'router/executing-operations/defer-support/',
  MigratingFromGateway: 'router/migrating-from-gateway/',

  // Tech Notes
  GraphSecurity: 'technotes/TN0021-graph-security/',

  // Resources
  LaunchStages: 'resources/product-launch-stages/',
});
